module.exports = [{
      plugin: require('/Users/applegatedt/workspace/danapplegate.github.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/applegatedt/workspace/danapplegate.github.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-112818899-2"},
    },{
      plugin: require('/Users/applegatedt/workspace/danapplegate.github.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/applegatedt/workspace/danapplegate.github.com/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/applegatedt/workspace/danapplegate.github.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
